<template>
  <Header />
  <div class="page">
    <slot />
  </div>
  <RenderCacheable
    :max-age="86400"
    :cache-key="[getUserLanguage.name, getHash].join('--')"
  >
    <Footer />
  </RenderCacheable>
</template>

<script setup>
import Header from "~~/modules/header/components/Header.vue";
import Footer from "~~/modules/footer/components/Footer.vue";
import { useLanguageStore } from "~/store/languageStore";
import { useCategoriesStore } from "~/store/categoriesStore";

const languageStore = useLanguageStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);
</script>
