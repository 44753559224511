<template>
  <li class="catalog-item">
    <div v-if="children(category).length > 0" class="catalog-item__wrapper">
      <div class="catalog-item__content">
        <div
          v-if="category.getImageUrl('menuImage', false)"
          class="catalog-item__image"
        >
          <img
            :src="category.getImageUrl('menuImage')"
            :alt="category.getName()"
          />
        </div>
        <span class="catalog-item__title">
          {{ category.getName() }}
        </span>
      </div>

      <div v-if="children(category).length > 0" class="item__image-arrow">
        <img src="~~/assets/icons/arrow-right.svg" alt="navigation arrow" />
      </div>
    </div>

    <MyLink
      v-else
      class="catalog-item__wrapper"
      name="category"
      :params="{ categorySlug: category.slug }"
      @click="activeLink"
    >
      <div class="catalog-item__content">
        <div
          v-if="category.getImageUrl('menuImage', false)"
          class="catalog-item__image"
        >
          <img
            :src="category.getImageUrl('menuImage')"
            :alt="category.getName()"
          />
        </div>
        <span class="catalog-item__title">
          {{ category.getName() }}
        </span>
      </div>
    </MyLink>
  </li>
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";
import { useCategoriesStore } from "~/store/categoriesStore";
import { Category } from "~/models/category.model";

const props = defineProps({
  category: { type: Category, required: true },
});

const categoriesStore = useCategoriesStore();
const { getChildren: children } = storeToRefs(categoriesStore);

const header = useHeaderStore();
const activeMenu = header.activeModal;

function activeLink() {
  activeMenu(false);
}
</script>

<style lang="scss">
.catalog-item {
  padding: 8px 16px;

  &__wrapper {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 8px;
  }

  &__content {
    @include flex-container(row, flex-start, center);

    gap: 16px;
  }

  &__image {
    width: 24px;
    height: 24px;
  }

  &__image-arrow {
    width: 24px;
    height: 24px;
  }

  &__title {
    @include font(16, 22, 400);
    color: #2b2b2b;
    letter-spacing: 0.02em;
  }
}
</style>
